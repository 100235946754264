<template>
  <div v-if="!isLoading">
    <!-- Banner -->
    <bg-header-container />
    <b-card v-if="errorInfo" class="px-lg-5">
      <feather-icon
        v-if="isStaff && !isEditing && isVerified"
        icon="Edit2Icon"
        size="20"
        style="cursor: pointer"
        class="text-primary float-right  mt-25"
        @click="isEditing=!isEditing"
      />
      <feather-icon
        v-if="isEditing"
        icon="XIcon"
        size="20"
        class="text-primary edit-icon float-right ml-1 mt-25"
        role="button"
        @click="openEditModal()"
      />
      <b-button
        v-if="hasButton && Object.keys(collective).length > 0 && !isEditing"
        variant="primary"
        class="float-right mr-1"
        @click="actions()"
      >
        {{ textButton }}
      </b-button>

      <h3>{{ $t("welcome.title", { name: loggedUser.name }) }},</h3>
      <p v-if="inMaintenance" class="font-weight-bolder">
        {{
          $t("welcome.maintenance", {
            item: $t(`collectives.items.item.${type}`),
          })
        }}
      </p>
      <p v-if="closed && !inMaintenance" class="mt-3 mb-2 font-medium-5 font-weight-500 text-danger">
        {{
          $t("welcome.closed", {
            item: $t(`collectives.items.item.${type}`),
            closed: $t(`welcome.item.${type}`),
          })[0].toUpperCase() +
            $t("welcome.closed", {
              item: $t(`collectives.items.item.${type}`),
              closed: $t(`welcome.item.${type}`),
            }).substring(1)
        }}
      </p>
      <p v-if="closed && !inMaintenance" class="font-medium-1">
        {{ $t("welcome.init-date") }} <span class="text-underline font-weight-600">{{ new Date(
          errorInfo.openingDate
        ).toLocaleString(currentLocale,dateOptions) }}<span v-if="errorInfo.closingDate != null"> {{ $t("welcome.finish-date") }}<span class="text-underline font-weight-600">{{ new Date(errorInfo.closingDate).toLocaleString(currentLocale,dateOptions) }}</span></span></span>
      </p>
      <p class="font-weight-bolder">
        <template
          v-if="errorInfo != null && errorInfo.message === 'invitationOnly'"
        >
          {{ $t("welcome.invitation-only") }}.
        </template>
        <template
          v-if="errorInfo != null && errorInfo.message === 'ParentSignup'"
        >
          {{ $t("welcome.parent-signup", { community: translate(collective.name) }) }}.
        </template>
        <template
          v-if="
            (errorInfo != null && errorInfo.canJoin) ||
              (errorInfo != null && errorInfo.canRequest) ||
              (errorInfo != null && errorInfo.message === 'Onboarding form')
          "
        >
          {{
            collectiveSlug === "barcelona-global"
              ? $t("welcome.request")
              : $t("welcome.no-member", { community: translate(collective.name) })
          }}.
        </template>
      </p>
      <span v-if="!isEditing" v-html="welcomeContent" />
      <div v-if="errorInfo.status === 7">
        <p>{{ $t("welcome.accept-invitation") }}</p>
        <b-button variant="success" class="mt-1" @click="canJoinSubcommunity">
          <feather-icon icon="CheckIcon" />
        </b-button>
        <b-button variant="danger" class="mt-1 ml-3" @click="declineInvitation">
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <b-form-select
        v-if="isEditing"
        v-model="selected"
        :options="locales"
        :placeholder="$t('form.type-placeholder')"
        label-type="text"
        class="mb-1"
        style="width: 200px"
      />
      <ckeditor
        v-if="isEditing"
        v-model="textToEdit.community.body[selected]"
        :editor="editor"
      />
      <b-button
        v-if="isEditing"
        variant="primary"
        class="float-right mt-1"
        @click="editWelcome()"
      >
        {{ $t("form.actions.save") }}
      </b-button>
      <b-alert
        v-if="
          collectiveSlug !== 'barcelona-global' &&
            errorInfo.status === 1 &&
            !hasButton
        "
        variant="primary"
        :show="
          showAlert || (errorInfo.requestedBefore && errorInfo.status !== 0)
        "
        style="width: fit-content"
      >
        {{ $t("welcome.request") }}
      </b-alert>
    </b-card>
    <b-card v-else class="px-lg-5">
      <p v-if="closed" class="font-weight-bolder">
        {{
          `${$t("welcome.closed", {
            item: $t(`collectives.items.item.${type}`),
          })} ${$t("welcome.init-date")} ${new Date(errorInfo.openingDate)} ${
            errorInfo.closingDate != null
              ? $t("welcome.finish-date") + new Date(errorInfo.closingDate)
              : null
          }`
        }}
      </p>
      <p v-else-if="inMaintenance" class="font-weight-bolder">
        {{
          $t("welcome.maintenance", {
            item: $t(`collectives.items.item.${type}`),
          })
        }}
      </p>
      <h2>{{ $t("welcome.title", { name: loggedUser.name }) }},</h2>
      <h3>
        {{
          $t("welcome.member", { item: $t(`collectives.items.item.${type}`) })
        }}.
      </h3>
      <span v-html="welcomeContent" />
      <!--       <b-button variant="primary" class="mt-1" @click="memberCommunityLink">
        {{ $t('welcome.enter') }}
      </b-button> -->
    </b-card>
  </div>
  <div v-else>
    <b-spinner class="d-block mx-auto mt-3" />
  </div>
</template>

<script>
import { BCard, BButton, BAlert } from 'bootstrap-vue';
import BgHeaderContainer from '@core/components/bg/BgHeaderContainer.vue';
import Service from '@/config/service-identifiers';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import * as Editor from '@core/editor';
import {
  checkCommunityDatesNew,
} from '@/store/collective/store-helpers';
import FaviconPlaceholder from '@/assets/images/placeholders/light/nectios-favicon.png';
import { getImageResource } from '@/@core/utils/image-utils';

export default {
  components: {
    BCard,
    BButton,
    BgHeaderContainer,
    BAlert,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      isLoading: true,
      showAlert: false,
      customText: null,
      isEditing: false,
      closed: false,
      inMaintenance: false,
      editor: Editor,
      textToEdit: '',
      selected: '',
      dateOptions: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    };
  },
  computed: {
    collective() {
      return this.$store.getters.currentCollective;
    },
    type() {
      if (this.collective.mainType) {
        return this.collective?.mainType;
      }
      return 'Community';
    },
    collectiveSlug() {
      return this.$route.params.communityId;
    },
    locales() {
      const { locales } = this.$store.getters;
      const communityLanguages = locales.filter((language) => this.availableLanguages.includes(language.value));
      return communityLanguages;
    },
    availableLanguages() {
      return this.collective?.availableLanguages;
    },
    currentLocale() {
      const { currentLocale } = this.$store.state.locale;
      const finalLanguage = this.availableLanguages?.find((item) => item === currentLocale)
        ? currentLocale
        : this.collective.languageCode;
      this.$store.dispatch('setLocale', finalLanguage, { root: true });
      return finalLanguage;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    isVerified() {
      return this.collective?.status === 3;
    },
    loggedUser() {
      const { loggedUser } = this.$store.getters;

      if (loggedUser.key == undefined) {
        // Redirect
      }

      return loggedUser;
    },
    hasButton() {
      try {
        const now = new Date().getTime();

        if (this.errorInfo.status === 1 || this.errorInfo.status === 7) {
          return false;
        }
        if (
          !!this.errorInfo.inMaintenance
          || (this.$store.getters.currentCollective != null
            && !!this.$store.getters.currentCollective.inMaintenance)
        ) {
          this.inMaintenance = true;
          if (this.$store.getters.currentCollective.isStaff) {
            return true;
          }
          return false;
        }
        if (!checkCommunityDatesNew(this.$store.getters.currentCollective)) {
          this.closed = true;
          if (
            this.$store.getters.currentCollective != null
            && this.$store.getters.currentCollective.isStaff
          ) {
            return true;
          }
          return false;
        }

        return (
          (this.errorInfo.message == null && this.errorInfo.canJoin == null)
          || ((this.errorInfo.requestedBefore === false
            || this.errorInfo.status === 0)
            && !this.showAlert
            && (this.errorInfo.canJoin
              || this.errorInfo.canRequest
              || this.errorInfo.message !== 'invitationOnly'))
        );
      } catch {
        return null;
      }
    },
    openEditModal() {
      if (this.textToEdit.community?.body) {
        this.textToEdit.community.body = this.customText;
      }
      this.isEditing = !this.isEditing;
    },
    welcomeContent() {
      return typeof this.customText === 'string'
        ? this.customText
        : translateTranslationTable(this.currentLocale, this.customText);
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    parentCollective() {
      return this.$store.getters.parentCollective;
    },
    textButton() {
      if (
        (this.errorInfo.message == null && this.errorInfo.canJoin == null)
        || (this.$store.getters.currentCollective != null
          && this.$store.getters.currentCollective.isStaff
        )
      ) {
        return this.$t('welcome.enter');
      }
      if (this.errorInfo.message === 'ParentSignup') {
        return this.$t('welcome.parent-signup-action');
      }
      if (this.errorInfo.canJoin) {
        return this.$t('welcome.join');
      }
      if (this.errorInfo.canRequest) {
        return this.$t('welcome.request-join');
      }
      return this.$t('welcome.onboarding-form');
    },
    errorInfo() {
      try {
        return this.$store.getters.currentCollective;
      } catch (e) {
        return null;
      }
    },
  },
  async created() {
    console.log('collectiveSlug:', this.collectiveSlug);
    this.isLoading = true;
    await this.$store.dispatch('fetchLoggedUser');
    await this.$store.dispatch('initCurrentCommunity', {
      communitySlug: this.collectiveSlug,
    });
    if (this.collective) {
      this.selected = this.currentLocale;
    }
    this.isLoading = false;
    this.getWelcomeData();
    if (this.errorInfo) {
      document.title = this.translate(this.errorInfo.name);
      const links = document.querySelectorAll("link[rel~='icon']");
      for (let index = 0; index < links.length; index += 1) {
        const link = links[index];
        link.href = getImageResource(this.errorInfo.logoURL) || FaviconPlaceholder;
      }
    }
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    async getWelcomeData() {
      const response = await this.$store.$service[Service.BackendClient].get(
        '/welcome',
        {
          params: {
            communitySlug: this.collectiveSlug,
          },
        },
      );
      if (response?.data?.community.body === null) {
        response.data.community.body = {};
      }
      if (typeof response.data.community.body === 'string') {
        const a = response.data.community.body;
        response.data.community.body = {};
        response.data.community.body[this.currentLocale] = a;
      }
      this.customText = typeof response?.data?.community.body === 'string' ? response?.data?.community.body : { ...response?.data?.community.body };
      this.textToEdit = { ...response?.data };
      /*if (this.collective.showChatbot === 1) {
        const chatBot = document.createElement('script');
        chatBot.innerText = 'window.$crisp=[];window.CRISP_WEBSITE_ID="a1aa36a2-c0e5-44a4-bc75-c5f95f1af801";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
        document.body.appendChild(chatBot);
      }*/
      if (this.collective.showChatbot === 2) {
        const chatBot = document.createElement('div');
        chatBot.innerHTML = this.$store.getters.currentCollective?.customChatbot;
        document.body.appendChild(chatBot);
      }
    },
    async editWelcome() {
      try {
        const response = await this.$store.dispatch('editItem', {
          item: {
            itemType: 'backoffice/welcome',
            requestConfig: {
              ...this.textToEdit,
            },
            customName: 'welcome',
          },
        });
        this.customText = response?.data?.community?.body;
        this.isEditing = false;
        this.notifySuccess(this.$t('success-message.general-success-edit'));
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    actions() {
      this.$store.dispatch('initCurrentCommunity', {
        communitySlug: this.collectiveSlug,
        force: true,
      });
      const links = document.querySelectorAll("link[rel~='icon']");
      for (let index = 0; index < links.length; index += 1) {
        const link = links[index];
        link.href = this.errorInfo.logoURL;
      }

      if (this.errorInfo.message == null && this.errorInfo.canJoin == null) {
        return this.$router.push({
          path: `/${this.$route.params.communitySlug}`,
        });
      }

      if (this.errorInfo.message === 'Onboarding form') {
        return (window.location.href = process.env.VUE_APP_ONBOARDING_FORM_URL_COMMUNITY.replace(
          '{collectiveSubdomain}',
          'app',
        ).replace('{collectiveSlug}', this.errorInfo.slug));
      }
      if (this.errorInfo.message === 'ParentSignup') {
        if (this.parentCollective.slug) {
          return (window.location.href = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
            '{subdomain}',
            this.parentCollective.subdomain || 'app',
          ).replace('{slug}', this.parentCollective.slug));
        }
        return (window.location.href = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
          '{subdomain}',
          this.mainCollective.subdomain || 'app',
        ).replace('{slug}', this.mainCollective.slug));
      }
      if (this.errorInfo.canRequest) {
        return this.canRequestCommunity();
      }
      if (this.errorInfo.canJoin) {
        return this.canJoinSubcommunity();
      }
      return '';
    },
    async canJoinSubcommunity() {
      try {
        await this.$store.$service[Service.BackendClient].post(
          'joinsubcommunity',
          {
            communitySlug: this.errorInfo.slug,
          },
        );
        window.location.href = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
          '{subdomain}',
          'app',
        ).replace('{slug}', this.errorInfo.slug);
      } catch (error) {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    async canRequestCommunity() {
      try {
        const requestJoin = await this.$store.$service[Service.BackendClient].post(
          'requestjoinsubcommunity',
          {
            communitySlug: this.errorInfo.slug,
          },
        );
        if (requestJoin.data.userRequested) {
          this.collective.status = 8;
        }
        this.notifySuccess(this.$t('welcome.request'));
        this.showAlert = true;
        await this.$store.dispatch('fetchLoggedUser');

        this.$store.dispatch('initCurrentCommunity', {
          communitySlug: this.collectiveSlug,
          force: true,
        });
      } catch (error) {
        console.log(error);
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    async declineInvitation() {
      try {
        await this.$store.$service[Service.BackendClient].put(
          'declineInvitation',
          {
            communitySlug: this.errorInfo.slug,
          },
        );
        this.errorInfo.status = 4;
        this.$store.dispatch('initCurrentCommunity', {
          communitySlug: this.collectiveSlug,
          force: true,
        });
      } catch (error) {
        console.log(error);
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    communityLink() {
      window.location.href = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        '{subdomain}',
        'app',
      ).replace('{slug}', this.errorInfo.slug);
    },
    memberCommunityLink() {
      window.location.href = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        '{subdomain}',
        this.collective.subdomain || 'app',
      ).replace('{slug}', this.collectiveSlug);
    },
    getBannerStyle() {
      this.bannerStyle = {
        'background-color':
          this.collective.header?.banner_image_background_color || '#ffffff',
        height: this.collective.header?.banner_image_height || '180px',
        'object-fit':
          this.collective.header?.banner_image_background_size || 'cover',
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/home.scss";
.ck-editor__editable {
  min-height: 300px;
}
</style>
