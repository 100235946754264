<template>
  <div>
    <banner-edition
      v-if="isHome && isEditingLayout && canEditLayout"
      :banner-url="bannerChanged || bannerUrl"
      :banner-url-mobile="bannerChangedMobile || bannerUrlMobile"
      :banner-data="collective.header"
      @banner-changed="handleChangeBanner"
    />

    <bg-header
      v-if="isMobile ? showBannerMobile : showBanner"
      :collective="collective || errorInfo"
      :banner-url="isMobile ? bannerUrlMobile : bannerUrl"
      class="header-banner"
      :class="isHome ? 'mt-2':'mt-n1'"
    />
  </div>
</template>

<script>
import BgHeader from '@core/components/bg/BgHeader.vue';
import { getImageResource } from '@/@core/utils/image-utils';
import BannerPlaceholder from '@/assets/images/placeholders/light/bg_banner.png';
import BannerEdition from '@core/components/bg/BannerEdition.vue';

export default {
  components: { BgHeader, BannerEdition },
  props: {
    isHome: {
      type: Boolean,
      default: false,
    },
    isEditingLayout: { type: Boolean, default: false },
    canEditLayout: { type: Boolean, default: false },
  },
  data() {
    return {
      bannerStyle: null,
      isLoading: false,
      bannerChanged: null,
      bannerChangedMobile: null,
    };
  },
  computed: {
    collective() {
      return this.$store.getters.currentCollective;
    },
    errorInfo() {
      try {
        return this.$store.getters.currentCollective;
      } catch (e) {
        return null;
      }
    },
    errorInfoBg() {
      return this.errorInfo?.data?.bgURL;
    },
    bannerUrl() {
      if (Array.isArray(this.collective.header) && this.collective.header.length) {
        return getImageResource(this.collective.header[0].banner_url);
      }
      if (this.collective.header?.banner_url != null) {
        return getImageResource(this.collective.header?.banner_url);
      } if (this.collective.bgURL != null) {
        return getImageResource(this.collective.bgURL);
      }
      return null;
    },
    bannerUrlMobile() {
      if (Array.isArray(this.collective.header) && this.collective.header.length) {
        return getImageResource(this.collective.header[0]);
      }
      return getImageResource(this.collective.header?.banner_url_mobile) || this.bannerPlaceholder;
    },
    bannerPlaceholder() {
      return BannerPlaceholder;
    },

    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    isMobile() {
      return window.innerWidth < 700;
    },
    showBanner() {
      return this.collective?.header?.show_banner && this.bannerUrl != null;
    },
    showBannerMobile() {
      return this.collective?.header?.show_banner_mobile && this.bannerUrlMobile != null;
    },
  },
  watch: {
    bannerStyle(value) {
      return value;
    },
    bannerUrl(value) {
      return value;
    },
    isMobile(value) {
      return value;
    },
  },
  async created() {
    this.bannerChanged = null;
    this.bannerChangedMobile = null;
    this.getBannerStyle();
  },

  methods: {
    getBannerStyle() {
      if (this.isMobile) {
        this.bannerStyle = {
          'background-color': this.collective?.header?.banner_image_background_color_mobile || '#ffffff',
          'max-height': this.collective?.header?.banner_image_height_mobile || '120px',
          'object-fit': this.collective?.header?.banner_image_background_size_mobile || 'cover',
        };
      } else {
        this.bannerStyle = {
          'background-color': this.collective?.header?.banner_image_background_color || '#ffffff',
          'max-height': this.collective?.header?.banner_image_height || '180px',
          'object-fit': this.collective?.header?.banner_image_background_size || 'cover',
        };
      }
    },
    async handleChangeBanner(image, banner, binariImage, imageMobile, bannerMobile, binariImageMobile) {
      this.isLoading = true;
      this.bannerChanged = image;
      this.bannerChangedMobile = imageMobile;

      if (this.collective.header) {
        this.isMobile
          ? (this.collective.header.show_banner = bannerMobile.showBanner)
          : (this.collective.header.show_banner = banner.showBanner);
      }
      this.isMobile
        ? (this.bannerStyle = {
          'background-color': bannerMobile.color,
          'max-height': bannerMobile.maxHeight,
          'background-size': bannerMobile.backgroundSize,
          'object-fit': bannerMobile.backgroundSize,
        })
        : (this.bannerStyle = {
          'background-color': banner.color,
          'max-height': banner.maxHeight,
          'background-size': banner.backgroundSize,
          'object-fit': banner.backgroundSize,
        });
      this.render = this.bannerStyle;

      const headerData = {
        /* Desktop */
        banner_image_background_color: banner.color,
        banner_image_background_size: banner.backgroundSize,
        banner_image_height: banner.height,
        show_banner: banner.showBanner,
        //  banner_url: banner.banner_url,
        /* Mobile */
        banner_image_background_color_mobile: bannerMobile.color,
        banner_image_background_size_mobile: bannerMobile.backgroundSize,
        banner_image_height_mobile: bannerMobile.height,
        show_banner_mobile: bannerMobile.showBanner,
        //    banner_url_mobile: bannerMobile.banner_url_mobile,
      };
      if (!banner.banner_url && !binariImage) {
        headerData.delete_header_banner_url = true;
      }
      if (!bannerMobile.banner_url_mobile && !binariImageMobile) {
        headerData.delete_header_banner_url_mobile = true;
      }
      const images = { banner_url: binariImage, banner_url_mobile: binariImageMobile };
      const isBgURL = !this.collective.header;

      await this.$store.dispatch('editCommunity', { headerData, images, isBgURL });
      this.isLoading = false;
    },
  },
};
</script>

<style>
  .header-banner {
    width: 100%;
    border-radius: 15px;
  }
</style>
