<template>
  <div>
    <span class="d-flex">
      <h4 class="font-weight-bolder mr-50">{{ $t('banner.label') }}</h4>
      <feather-icon
        v-b-modal.modal-edit-banner
        icon="Edit2Icon"
        size="20"
        class="text-primary"
        @click="isModalOpened = true"
      />
    </span>
    <b-modal
      id="modal-edit-banner"
      v-model="isModalOpened"
      :title="$t('banner.title')"
      size="xl"
      hide-footer
      no-close-on-backdrop
      @show="getBannerData"
    >
      <!-- Desktop - Mobile selector -->
      <div class="d-flex justify-content-around mt-1 flex-wrap">
        <div
          class="banner-selector-background d-block mx-auto mt-50"
          :class="{ 'banner-selector-background--active': isDesktop }"
          @click="isDesktop = true"
        >
          <feather-icon
            icon="MonitorIcon"
            size="30"
            class="d-block mx-auto"
            :class="isDesktop ? 'text-primary' : 'text-secondary'"
          />
          <h5 class="mt-50" :class="isDesktop ? 'text-primary' : 'text-secondary'">
            {{ $t('banner.desktop-banner') }}
          </h5>
        </div>
        <div
          class="banner-selector-background d-block mx-auto mt-50"
          :class="{ 'banner-selector-background--active': !isDesktop }"
          @click="isDesktop = false"
        >
          <feather-icon
            icon="SmartphoneIcon"
            size="30"
            class="d-block mx-auto"
            :class="!isDesktop ? 'text-primary' : 'text-secondary'"
          />
          <h5 class="mt-50" :class="!isDesktop ? 'text-primary' : 'text-secondary'">
            {{ $t('banner.mobile-banner') }}
          </h5>
        </div>
      </div>

      <b-form v-if="!isLoading">
        <!-- Desktop -->
        <div v-if="isDesktop">
          <b-media no-body vertical-align="center" class="d-flex align-items-center flex-column mt-2">
            <div class="header-banner mt-2 imageBanner">
              <b-img
                ref="refPreviewEl"
                :src="imageSrc"
                width="550"
                class="rounded mb-1 mb-md-0"
                :class="banner.showBanner?'':'banner-show'"
                style="background: #c0c0c0"
                :style="isHeaderAuto ? headerBgAuto : headerBg"
              />
              <button-close v-if="imageSrc" class="imageBanner__cancel" @click.prevent="deleteImage" />
              <div v-if="!banner.showBanner" class="imageBanner__eye h-100 w-100 imageBanner__background rounded d-flex align-items-center justify-content-center">
                <b-icon-eye-slash font-scale="7" variant="white" />
                <p class="text-white mb-0 font-weight-500 font-large-4 ml-3">
                  No Banner
                </p>
              </div>
            </div>
            <div class="mt-2">
              <b-form-file
                v-model="imageChallenge"
                :accept="FileTypes"
                class="pointer"
                style="width: 550px"
                :placeholder="$t('form-create-item.image-placeholder')"
                :browse-text="$t('form-create-item.image-button')"
              />
            </div>
          </b-media>
          <b-row class="banner-container mx-auto mt-1">
            <b-col>
              <b-form-group>
                <label for="show-banner" class="font-weight-bold">{{ $t('banner.show-banner') }}:</label>
                <b-form-checkbox id="show-banner" v-model="banner.showBanner" switch />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label for="height" class="font-weight-bold" :class="isHeaderAuto?'text-muted':''">{{ $t('banner.height') }}:</label>
                <b-form-input
                  id="height"
                  v-model="banner.height"
                  :disabled="isHeaderAuto"
                  type="text"
                  class="banner-input"
                  :class="isHeaderAuto?'text-muted':''"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="banner-container mx-auto">
            <b-col>
              <b-form-group>
                <label for="height" class="font-weight-bold">{{ $t('banner.color') }}:</label>
                <b-form-input
                  id="height"
                  v-model="banner.color"
                  type="color"
                  class="banner-input"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label for="height" class="font-weight-bold">{{ $t('banner.background-fit.title') }}:</label>
                <br>
                <v-select
                  id="height"
                  v-model="banner.backgroundSize"
                  :clearable="false"
                  :placeholder="$t('form.select-placeholder')"
                  :options="backgroundFitOptions"
                  :reduce="(backgroundFit)=>backgroundFit.key"
                  label="name"
                  class="banner-input"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <b-media no-body vertical-align="center" class="d-flex align-items-center flex-column mt-2">
            <div class="header-banner mt-2 imageBanner image-mobile">
              <b-img
                ref="refPreviewEl"
                :src="imageSrcMobile"
                width="550"
                class="rounded mb-1 mb-md-0"
                :class="bannerMobile.showBanner?'':'banner-show'"
                style="background: #c0c0c0"
                :style="isHeaderAutoMobile ? headerBgAutoMobile : headerBgMobile"
              />
              <button-close v-if="imageSrcMobile" class="imageBanner__cancel" @click.prevent="deleteImageMobile" />
              <div v-if="!bannerMobile.showBanner" class="imageBanner__eye h-100 w-100 imageBanner__background rounded d-flex align-items-center justify-content-center">
                <b-icon-eye-slash font-scale="7" variant="white" />
                <p class="text-white mb-0 font-weight-500 font-large-4 ml-3">
                  No Banner
                </p>
              </div>
            </div>
            <div class="mt-2">
              <b-form-file
                v-model="imageChallengeMobile"
                :accept="FileTypes"
                class="pointer"
                style="width: 550px"
                :placeholder="$t('form-create-item.image-placeholder')"
                :browse-text="$t('form-create-item.image-button')"
              />
            </div>
          </b-media>
          <b-row class="banner-container mx-auto mt-1">
            <b-col>
              <b-form-group>
                <label for="show-banner" class="font-weight-bold">{{ $t('banner.show-banner') }}:</label>
                <b-form-checkbox id="show-banner" v-model="bannerMobile.showBanner" switch />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label for="height" class="font-weight-bold" :class="isHeaderAutoMobile?'text-muted':''">{{ $t('banner.height') }}:</label>
                <b-form-input
                  id="height"
                  v-model="bannerMobile.height"
                  type="text"
                  :disabled="isHeaderAutoMobile"
                  class="banner-input"
                  :class="isHeaderAutoMobile?'text-muted':''"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="banner-container mx-auto">
            <b-col>
              <b-form-group>
                <label for="height" class="font-weight-bold">{{ $t('banner.color') }}:</label>
                <b-form-input
                  id="height"
                  v-model="bannerMobile.color"
                  type="color"
                  class="banner-input"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label for="height" class="font-weight-bold">{{ $t('banner.background-fit.title') }}:</label>
                <br>
                <v-select
                  id="height"
                  v-model="bannerMobile.backgroundSize"
                  :clearable="false"
                  :placeholder="$t('form.select-placeholder')"
                  :options="backgroundFitOptions"
                  :reduce="(backgroundFit)=>backgroundFit.key"
                  label="name"
                  class="banner-input"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <b-alert show variant="danger" class="text-center border-0 mt-1">
          <feather-icon icon="AlertTriangleIcon" size="16" class="mr-25" /> {{ $t('banner.alert') }}
        </b-alert>
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isSending"
          @click="handleChangeBanner"
        >
          <span
            v-if="isSending"
            class="d-flex "
          ><b-spinner
             class="mr-1"
             small
             variant="white"
             label="Loading..."
           />
            {{ $t('form.actions.saving') }}
          </span>
          <span v-else>{{ $t('form.actions.save') }}</span>
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import FileTypes from '@/@core/constants/FileTypes';
import vSelect from 'vue-select';
import ButtonClose from '@core/components/buttons/ButtonClose.vue';

export default {
  components: { vSelect, ButtonClose },
  mixins: [ToastNotificationsMixin],
  props: {
    bannerUrl: {
      type: String,
      default: '',
    },
    bannerUrlMobile: {
      type: String,
      default: '',
    },
    bannerData: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      imageChallenge: null,
      imageSrc: null,
      imageChallengeMobile: null,
      imageSrcMobile: null,
      isDesktop: true,
      isLoading: false,
      isSending: false,
      isModalOpened: false,
      banner: {
        showBanner: true,
        height: /*  */ null,
        backgroundSize: null,
        backgroundColor: null,
        color: null,
      },
      bannerMobile: {
        showBanner: true,
        height: null,
        backgroundSize: null,
        backgroundColor: null,
        color: null,
      },
    };
  },
  computed: {
    FileTypes() {
      return FileTypes;
    },
    backgroundFitOptions() {
      return [{ name: this.$t('banner.background-fit.cover'), key: 'cover' }, { name: this.$t('banner.background-fit.contain'), key: 'contain' }, { name: this.$t('banner.background-fit.auto'), key: 'auto' }, { name: this.$t('banner.background-fit.unset'), key: 'unset' }];
    },
    isHeaderAuto() {
      return this.banner.backgroundSize === 'auto';
    },
    isHeaderAutoMobile() {
      return this.bannerMobile.backgroundSize === 'auto';
    },
    headerBg() {
      return {
        'object-fit': this.banner.backgroundSize,
        height: this.banner.height,
        'max-height': this.banner.height,
        'background-color': this.banner.color,
      };
    },
    headerBgMobile() {
      return {
        'object-fit': this.bannerMobile.backgroundSize,
        height: this.bannerMobile.height,
        'max-height': this.bannerMobile.height,
        'background-color': this.bannerMobile.color,
      };
    },
    headerBgAuto() {
      return {
        // In option 'auto', the measures of the banner given by the client must be respected
        'background-color': this.banner.color,
        height: '100%',
      };
    },
    headerBgAutoMobile() {
      return {
        // In option 'auto', the measures of the banner given by the client must be respected
        'background-color': this.bannerMobile.color,
        height: '100%',
      };
    },
  },
  watch: {
    imageChallenge(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
              this.banner.showBanner = true;
            })
            .catch(() => {
              this.imageSrc = null;
              this.banner.showBanner = false;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
    imageChallengeMobile(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrcMobile = value;
              this.bannerMobile.showBanner = true;
            })
            .catch(() => {
              this.imageSrcMobile = null;
              this.bannerMobile.showBanner = false;
            });
        } else {
          this.imageSrcMobile = null;
        }
      }
    },
  },
  created() {
    this.isLoading = true;
    this.getBannerData();
    this.isLoading = false;
  },
  methods: {
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    deleteImage() {
      this.imageSrc = null;
      this.banner.banner_url = null;
      this.banner.showBanner = false;
    },
    deleteImageMobile() {
      this.imageSrcMobile = null;
      this.bannerMobile.banner_url_mobile = null;
      this.bannerMobile.showBanner = false;
    },
    handleChangeBanner() {
      this.isSending = true;
      try {
        this.$emit(
          'banner-changed',
          this.imageSrc,
          this.banner,
          this.imageChallenge,
          this.imageSrcMobile,
          this.bannerMobile,
          this.imageChallengeMobile,
        );
        this.notifySuccess(this.$t('banner.messages.success'));
        this.isModalOpened = false;
        this.imageSrc = null;
        this.imageSrcMobile = null;
        this.isSending = false;
      } catch {
        this.notifyError(this.$t('banner.messages.error'));
        this.isSending = false;
      }
    },
    getBannerData() {
      /* Desktop */
      this.imageSrc = this.bannerUrl || this.bannerUrlMobile;
      this.banner.banner_url = this.bannerUrl || this.bannerUrlMobile;
      this.banner.showBanner = this.bannerUrl !== null ? this.bannerData ? this.bannerData?.show_banner : true : false;
      this.banner.height = this.bannerData?.banner_image_height || '180px';
      this.banner.color = this.bannerData?.banner_image_background_color || '#ffffff';
      this.banner.backgroundSize = this.bannerData?.banner_image_background_size || 'cover';
      /* Mobile */
      this.imageSrcMobile = this.bannerUrlMobile || this.bannerUrl;
      this.bannerMobile.banner_url_mobile = this.bannerUrlMobile || this.bannerUrl;
      this.bannerMobile.showBanner = this.bannerUrl !== null ? this.bannerData ? this.bannerData?.show_banner_mobile : true : false;
      this.bannerMobile.height = this.bannerData?.banner_image_height_mobile || '180px';
      this.bannerMobile.color = this.bannerData?.banner_image_background_color_mobile || '#ffffff';
      this.bannerMobile.backgroundSize = this.bannerData?.banner_image_background_size_mobile || 'cover';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.banner {
  &-container {
    width: 34.375rem;
  }
  &-input {
    width: 15rem;
  }
}
.banner-selector-background {
  cursor: pointer;
  &--active {
    color: $primary;
    cursor: pointer;
    padding-bottom: 10px;
    border-bottom: solid $primary 2px;
  }
}
.header-banner {
  width: 100%;
  border: 1px solid $border-color;
  border-radius: 1.2rem;
  margin-bottom: 2rem;
}
.imageBanner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &__cancel {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  &__eye{
        position: absolute;
        opacity: 1;
  }
  &__background{
    background-color: rgba(0,0,0,0.5);
  }
}
.image-mobile {
  width: 700px;
}
.banner-show {
  opacity:0.3;

}
</style>
