<template>
  <collective-detail
    v-bind="currentProperties"
    :from-welcome="true"
    class="mt-2"
  />
</template>

<script>
import CollectiveDetail from '@/views/apps/collectives/components/CollectiveDetail.vue';

export default {
  name: 'MembershipEventDetails',
  components: {
    CollectiveDetail,
  },
  computed: {
    collectiveDetail() {
      return 'CollectiveDetail';
    },
    item() {
      return this.$store.getters.currentCollective;
    },
    currentProperties() {
      if (this.item.mainType === 'Event') {
        return {
          breadcrumbRoute: 'membership-events',
          itemType: 'events',
          itemTypeSingle: 'event',
          customItemType: 'membership-event',
        };
      }
      if (this.item.mainType === 'Project') {
        return {
          breadcrumbRoute: 'projects',
          itemType: 'projects',
          itemTypeSingle: 'project',
          hasLikes: true,
        };
      }
      if (this.item.mainType === 'Course') {
        return {
          breadcrumbRoute: 'courses',
          itemType: 'courses',
          itemTypeSingle: 'course',
        };
      }
      if (this.item.mainType === 'Challenge') {
        return {
          breadcrumbRoute: 'challenges',
          itemType: 'challenges',
          itemTypeSingle: 'challenge',
        };
      }
      if (this.item.mainType === 'Organization') {
        return {
          breadcrumbRoute: 'organizations',
          itemType: 'organizations',
          itemTypeSingle: 'organization',
        };
      }
      if (this.item.mainType === 'Subcommunity') {
        return {
          breadcrumbRoute: 'subcommunities',
          itemType: 'subcommunities',
          itemTypeSingle: 'subcommunity',
        };
      }
      if (this.item.mainType === 'Service') {
        return {
          breadcrumbRoute: 'services',
          itemType: 'services',
          itemTypeSingle: 'service',
        };
      }
      return {
        breadcrumbRoute: 'subcommunities',
        itemType: 'subcommunities',
        itemTypeSingle: 'subcommunity',
      };
    },
  },
};
</script>
