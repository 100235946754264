<template>
  <safe-img :src="bannerUrl" :placeholder="placeholder" :style="isHeaderAuto ? headerBgAuto : headerBg" />
</template>

<script>
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import HeaderPlaceholder from '@/assets/images/placeholders/light/header.jpeg';

export default {
  name: 'BgHeader',
  components: { SafeImg },
  props: {
    collective: {
      type: Object,
      required: true,
    },
    bannerUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    isMobile() {
      return window.innerWidth < 700;
    },
    placeholder() {
      return HeaderPlaceholder;
    },
    isHeaderAuto() {
      if (this.isMobile) return this.collective.header?.banner_image_background_size_mobile === 'auto';
      return this.collective.header?.banner_image_background_size === 'auto';
    },
    headerBg() {
      if (this.isMobile) {
        return {
          'background-color': this.collective?.header?.banner_image_background_color_mobile,
          'max-height': this.collective?.header?.banner_image_height_mobile,
          'object-fit': this.collective?.header?.banner_image_background_size_mobile,
        };
      }
      return {
        'background-color': this.collective?.header?.banner_image_background_color,
        'max-height': this.collective?.header?.banner_image_height,
        'object-fit': this.collective?.header?.banner_image_background_size,
      };
    },
    headerBgAuto() {
      // In option 'auto', the measures of the banner given by the client must be respected
      if (this.isMobile) {
        return {
          'background-color': this.collective.header?.banner_image_background_color_mobile,
        };
      }
      return {
        'background-color': this.collective.header?.banner_image_background_color,
      };
    },
  },
};
</script>
